/** @jsx jsx */
import { jsx, Styled, Flex } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import { hideAllNotifications, showNotification, SEVERITY } from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Field, Form } from 'formik';
import { Container, RadioField, RadioFieldGroup } from '../components';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { locNavigate } from '../state/session';
import { handleFormValidationError } from '../utils/formError';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import testLogger from '../components/test-logger';
import { deviceDetect } from 'react-device-detect';
import * as analytics from '../utils/analytics';
import Link from '../components/Link';

const valueTicketOrderSchema = translate =>
  yup.object().shape({
    amount: yup.number().required(translate('valueTicketOrderForm.requiredField')),
    // valueLoaded: yup.string().required(translate('valueTicketOrderForm.requiredField')),
    // value: yup.string(),
    name: yup.string().required(translate('valueTicketOrderForm.requiredField')),
    email: yup
      .string()
      .required(translate('valueTicketOrderForm.requiredField'))
      .email(translate('valueTicketOrderForm.invalidEmail')),
    address: yup.string().required(translate('valueTicketOrderForm.requiredField')),
    postalCode: yup.string().required(translate('valueTicketOrderForm.requiredField')),
    city: yup.string().required(translate('valueTicketOrderForm.requiredField')),
    phoneNumber: yup.string().required(translate('valueTicketOrderForm.requiredField')),
    receiverName: yup.string(),
    receiverEmail: yup.string(),
    receiverAddress: yup.string(),
    receiverPostalCode: yup.string(),
    receiverCity: yup.string(),
    receiverPhoneNumber: yup.string(),
    recaptcha: yup.string().required(),
  });

const ValueTicketOrderForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  errors,
  nocaptcha,
  values,
  touched,
}) => {
  // const hasTouched = values?.valueLoaded === 'yes' && Object.keys(touched).includes('value');
  // const hasValues = (values?.valueLoaded === 'yes' && !!values?.value) || values?.valueLoaded === 'no';

  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Flex>
        <FormField type="number" min={1} name="amount" label={translate('valueTicketOrderForm.howManySavonlinna')} />
      </Flex>
      {/*
      <RadioFieldGroup id="valueLoaded" label="">
        <Field
          component={RadioField}
          name="valueLoaded"
          id="yes"
          label={translate('valueTicketOrderForm.valueTicket')}
        />
        {values.valueLoaded === 'yes' ? (
          <>
            <div sx={{ my: 3 }}>
              <Flex sx={{ alignItems: 'center', gap: 3 }}>
                <FormField type="number" name="value" label={translate('valueTicketOrderForm.valueToLoad')} />
                <p>€</p>
              </Flex>
              {hasTouched && !hasValues ? (
                <p sx={{ color: 'danger', mt: -3, fontSize: 1 }}>{translate('valueTicketOrderForm.requiredField')}</p>
              ) : null}
            </div>
          </>
        ) : null}
        <Field component={RadioField} name="valueLoaded" id="no" label={translate('valueTicketOrderForm.noValue')} />
      </RadioFieldGroup>
      */}
      <p>{translate('valueTicketOrderForm.sentToEmail')}</p>
      <p>{translate('valueTicketOrderForm.sendText')}</p>
      <Styled.h2>{translate('valueTicketOrderForm.contact.buyerTitle')}</Styled.h2>
      <FormField name="name" label={`${translate('valueTicketOrderForm.contact.name')} *`} />
      <FormField name="email" label={`${translate('valueTicketOrderForm.contact.email')} *`} />
      <FormField name="address" label={`${translate('valueTicketOrderForm.contact.address')} *`} />
      <FormField name="postalCode" label={`${translate('valueTicketOrderForm.contact.postalCode')} *`} />
      <FormField name="city" label={`${translate('valueTicketOrderForm.contact.city')} *`} />
      <FormField name="phoneNumber" label={`${translate('valueTicketOrderForm.contact.phone')} *`} />

      <Styled.h2>{translate('valueTicketOrderForm.contact.receiverTitle')}</Styled.h2>
      <FormField name="receiverName" label={translate('valueTicketOrderForm.contact.name')} />
      <FormField name="receiverEmail" label={translate('valueTicketOrderForm.contact.email')} />
      <FormField name="receiverAddress" label={translate('valueTicketOrderForm.contact.address')} />
      <FormField name="receiverPostalCode" label={translate('valueTicketOrderForm.contact.postalCode')} />
      <FormField name="receiverCity" label={translate('valueTicketOrderForm.contact.city')} />
      <FormField name="receiverPhoneNumber" label={translate('valueTicketOrderForm.contact.phone')} />
      <p>
        {translate('valueTicketOrderForm.privacy')}{' '}
        <Link target="__blank" to={{ external: true, link: 'https://www.matkahuolto.fi/tietosuoja' }}>
          www.matkahuolto.fi/tietosuoja.
        </Link>{' '}
      </p>
      <p>
        {translate('valueTicketOrderForm.acceptTerms')}{' '}
        <Link
          to={{
            external: true,
            link: 'https://www.matkahuolto.fi/matkustajat/arvolipun-tilaamisen-lippu-ja-toimitusehdot',
          }}
          target="__blank"
        >
          {translate('valueTicketOrderForm.terms')}.
        </Link>
      </p>
      <p>{translate('valueTicketOrderForm.info')}</p>

      <RecaptchaButton
        buttonText="valueTicketOrderForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
        errors={errors}
        // disabled={!hasValues}
      />
    </Form>
  );
};

const ValueTicketOrderFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      amount: vo.amount || '',
      valueLoaded: vo?.valueLoaded,
      value: vo.value || '',
      name: vo.name || '',
      email: vo.email || '',
      address: vo.address || '',
      postalCode: vo.postalCode || '',
      city: vo.city || '',
      phoneNumber: vo.phoneNumber || '',
      receiverName: vo.receiverName || '',
      receiverEmail: vo.receiverEmail || '',
      receiverAddress: vo.receiverAddress || '',
      receiverPostalCode: vo.receiverCostalCode || '',
      receiverCity: vo.receiverCity || '',
      receiverPhoneNumber: vo.receiverPhoneNumber || '',
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return valueTicketOrderSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'ValueTicketOrderForm',
})(ValueTicketOrderForm);

const ValueTicketOrderFormPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendValueTicketOrderForm({ ...vo, savonlinna: true, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: {
              message: translate('valueTicketOrderForm.success'),
              noDefault: true,
            },
          })
        );
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        testLogger.log('sendValueTicketOrderForm:FAILED ' + error + ', ' + JSON.stringify(deviceDetect()));
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(showNotification('valueTicketOrderForm.failed', SEVERITY.ERROR));
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout
      title={translate('valueTicketOrderForm.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('valueTicketOrderForm.titleSavonlinna')}</Styled.h1>
        <p>{translate('valueTicketOrderForm.text1savonlinna')}</p>
        <p>
          {translate('valueTicketOrderForm.text2savonlinna')} {/* translate('valueTicketOrderForm.text3') */}
        </p>

        <ValueTicketOrderFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default ValueTicketOrderFormPage;
